import { Close } from '@air/next-icons';
import { formatDistance } from 'date-fns';
import { isNumber } from 'lodash';
import pluralize from 'pluralize';
import { memo } from 'react';

import { ProgressingAvatar } from '~/components/FileStatusTrackingPane/FileStatusTrackingPaneHeader/StatusAvatar';
import { PaneButton } from '~/components/FileStatusTrackingPane/PaneButton';
import { PaneItemTW } from '~/components/FileStatusTrackingPane/PaneItemTW';
import { GetInProgressMetadata, ZippingTask } from '~/store/tasks/types';

import { StatusTrackingSubtitle } from '../FileStatusTrackingPane/StatusTrackingSubtitle';
import { StatusTrackingTitle } from '../FileStatusTrackingPane/StatusTrackingTitle';

export type ZippingTaskInProgressPanelItemProps = {
  metadata: GetInProgressMetadata<ZippingTask>;
  onCancel: () => void;
};

export const ZippingTaskInProgressPanelItem = memo(
  ({ onCancel, metadata: { clipIds = [], boardIds = [], secondsRemaining } }: ZippingTaskInProgressPanelItemProps) => {
    return (
      <PaneItemTW
        avatar={<ProgressingAvatar />}
        title={
          <StatusTrackingTitle data-testid="ZIPPING_PANEL_IN_PROGRESS_TITLE">{`Zipping ${pluralize(
            'item',
            clipIds.length + boardIds.length,
            true,
          )}`}</StatusTrackingTitle>
        }
        subtitle={
          <StatusTrackingSubtitle className="truncate">
            {isNumber(secondsRemaining)
              ? `${formatDistance(new Date(Date.now() + secondsRemaining * 1000), new Date(), {
                  includeSeconds: true,
                })} left`
              : `Your download will begin shortly.`}
          </StatusTrackingSubtitle>
        }
        buttons={<PaneButton label="Cancel" Icon={Close} onClick={() => onCancel()} />}
      />
    );
  },
);

ZippingTaskInProgressPanelItem.displayName = 'ZippingInProgressPanelItem';
