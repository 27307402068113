import { Close as CloseIcon } from '@air/next-icons';
import { VisuallyHidden } from '@air/primitive-visually-hidden';
import * as RadixPopover from '@radix-ui/react-popover';
import classNames from 'classnames';

export type PopoverCloseButton = RadixPopover.PopoverCloseProps;

export const PopoverCloseButton = ({ className, ...restOfProps }: PopoverCloseButton) => {
  return (
    <RadixPopover.Close
      className={classNames(
        'hover:bg-pigeon-4 flex h-6 w-6 items-center justify-center rounded border-0 bg-transparent text-grey-12 outline-none focus-visible:ring-2 focus-visible:ring-blue-9 active:bg-grey-4',
        className,
      )}
      {...restOfProps}
    >
      <VisuallyHidden>Close popover</VisuallyHidden>
      <CloseIcon className="size-4" />
    </RadixPopover.Close>
  );
};
