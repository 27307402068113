import { tailwindMerge } from '@air/tailwind-variants';
import * as RadixTabs from '@radix-ui/react-tabs';
import classNames from 'classnames';
import type { ReactNode } from 'react';

export type TabPanel = {
  content: ReactNode;
  contentClassName?: string;
  disabled?: boolean;
  id: string;
  label: ReactNode | string;
  labelClassName?: string;
};

export type TabsProps = Pick<
  RadixTabs.TabsProps,
  'activationMode' | 'className' | 'defaultValue' | 'dir' | 'onValueChange' | 'orientation' | 'value'
> &
  Pick<RadixTabs.TabsListProps, 'loop'> & {
    defaultValue?: string;
    listClassName?: string;
    panels: TabPanel[];
    panelsClassName?: string;
    rightControls?: ReactNode;
  };

export const Tabs = ({
  activationMode,
  className,
  defaultValue,
  dir,
  listClassName,
  loop,
  onValueChange,
  orientation,
  panels = [],
  panelsClassName,
  rightControls,
  ...rest
}: TabsProps) => {
  const defaultTab = defaultValue || panels?.[0]?.id;

  return (
    <RadixTabs.Root
      activationMode={activationMode}
      className={className}
      defaultValue={defaultTab}
      dir={dir}
      onValueChange={onValueChange}
      orientation={orientation}
      {...rest}
    >
      <RadixTabs.List
        className={tailwindMerge('flex justify-between border-b border-b-grey-5', listClassName)}
        loop={loop}
      >
        <div className="flex gap-5">
          {panels?.map((panel) => (
            <RadixTabs.Trigger
              className={classNames(
                'group/tabs-trigger -mb-px cursor-pointer select-none border-b-2 border-t-2 border-b-transparent border-t-transparent text-12 font-semibold text-grey-12 radix-disabled:cursor-not-allowed radix-disabled:opacity-45 radix-state-active:border-b-blue-9 radix-state-active:text-grey-12',
                panel.labelClassName,
              )}
              disabled={panel.disabled}
              key={panel.id}
              value={panel.id}
              data-testid="TAB_TRIGGER"
              data-tabid={panel.id}
            >
              <div className="-mx-2 mb-1 flex items-center gap-1 rounded bg-transparent px-2 py-1 group-hover/tabs-trigger:bg-grey-4 group-active/tabs-trigger:bg-grey-4">
                {panel.label}
              </div>
            </RadixTabs.Trigger>
          ))}
        </div>
        {rightControls}
      </RadixTabs.List>

      {panels?.map((panel) => (
        <RadixTabs.Content
          className={classNames(panelsClassName, panel.contentClassName)}
          key={panel.id}
          value={panel.id}
        >
          {panel.content}
        </RadixTabs.Content>
      ))}
    </RadixTabs.Root>
  );
};
